<template>
  <div class="d-flex justify-content-between mb-8">
    <input
      class="form-control form-control-solid h-auto py-5 px-4 rounded-lg font-weight-bolder text-center bg-gray-300 fainputs"
      type="text"
      :name="`codigo${i}`"
      v-model="codigo[i]"
      autocomplete="off"
      style="font-size: 3rem !important; width: 65px !important"
      ref="codigos"
      :autofocus="i === 0"
      maxlength="1"
      @keydown="handleKeyDown($event, i)"
      v-for="(s, i) in tamanho"
      :key="i"
    />
  </div>
</template>

<script>
export default {
  name: "fa-input",

  data() {
    return {
      codigo: [],
      tamanho: 6
    };
  },

  mounted() {
    const inputs = this.$refs.codigos;

    inputs.forEach((input, index) => {
      input.addEventListener("paste", (event) => {
        event.preventDefault();

        let paste = (event.clipboardData || window.clipboardData).getData(
          "text"
        );

        if (new RegExp(/^\d+$/).test(paste)) {
          if (paste.length == this.tamanho) {
            this.codigo = paste.split("");
            this.$refs.codigos[this.tamanho - 1].focus();

            this.$emit("codigo:atualizado", paste);
          }
        }
      });
    });
  },

  methods: {
    handleKeyDown: function (event, index) {
      if (!(event.ctrlKey && event.code == "KeyV")) {
        if (
          event.key !== "Tab" &&
          event.key !== "ArrowRight" &&
          event.key !== "ArrowLeft"
        ) {
          event.preventDefault();
        }

        let codigos = [...this.codigo];

        if (event.key === "Backspace") {
          if (index != 0 && !codigos[index]) {
            codigos[index - 1] = null;

            this.$refs.codigos[index - 1].focus();
          } else {
            codigos[index] = null;
          }

          this.codigo = codigos;
          this.$emit("codigo:atualizado", this.codigo.join(""));
        } else if (new RegExp("^([0-9])$").test(event.key)) {
          this.preencherCodigo(event.key, index, codigos);
        }
      }
    },

    preencherCodigo(key, index, codigos) {
      if (index != 0 && !codigos[index - 1]) {
        this.preencherCodigo(key, index - 1, codigos);
      } else {
        codigos[index] = key;

        if (index != this.tamanho - 1) {
          this.$refs.codigos[index + 1].focus();
        }

        this.codigo = codigos;
        this.$emit("codigo:atualizado", this.codigo.join(""));
      }
    }
  }
};
</script>
